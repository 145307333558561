body {
	font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
	font-weight: 400;
	font-style: normal;
	font-size: 16px;
	line-height: 1.8;
	padding: 0;
	margin: 0;
	color: #545454 !important;
	background: #eff7f8;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
