.iq-card {
	background: #ffffff;
	-webkit-border-radius: 25px;
	-moz-border-radius: 25px;
	border-radius: 25px;
	margin-bottom: 30px;
	border: none;

	box-shadow: 0px 4px 5px 1px rgba(175, 175, 175, 0.1);
}
.iq-card-body {
	padding: 20px;
}

.iq-primary {
	background: #089bab !important;
	color: #ceebee !important;
}
.iq-bg-primary {
	background: #ceebee !important;
	color: #089bab !important;
}

.iq-warning {
	color: #fae9dd !important;
	background: #ffb177 !important;
}
.iq-bg-warning {
	background: #fae9dd !important;
	color: #ffb177 !important;
}
.iq-danger {
	color: #fcecec !important;
	background: #e64141 !important;
}
.iq-bg-danger {
	background: #fcecec !important;
	color: #e64141 !important;
}
.iq-info {
	color: #e5faff !important;
	background: #00d0ff !important;
}
.iq-bg-info {
	background: #e5faff !important;
	color: #00d0ff !important;
}
.iq-success {
	color: #d4edda !important;
	background: #28a745 !important;
}

.iq-bg-success {
	background: #d4edda !important;
	color: #28a745 !important;
}
.iq-secondary {
	color: #e2e3e5 !important;
	background: #6c757d !important;
}
.iq-bg-secondary {
	background: #e2e3e5 !important;
	color: #6c757d !important;
}

.iq-bg-dark {
	background: #343a40 !important;
	color: #adb5bd !important;
}

.iq-bg-light {
	background: #f8f9fa !important;
	color: #212529 !important;
}
.card-sec {
	padding: 10px 25px;
}
.chart-sec {
	background: white;
	border-radius: 15px;
	margin: 2%;
	/* width: 92%; */
	padding: 2%;
}
.excel-img,
.pdf-img {
	width: 25px;
}
.downloadAll {
	width: 20% !important;
}

.iq-card-icon {
	height: 60px;
	width: 60px;
	display: inline-block;
	line-height: 60px;
	text-align: center;
	font-size: 22px;
	border-radius: 50%;
}