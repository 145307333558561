.App {
  text-align: center;}
  body{
    overflow-y: hidden;
  }
.btn-primary {
  width: 40%;
  color: #ffffff;
  border-color: #089bab !important;
  background: linear-gradient(
    to right,
    rgba(8, 155, 171, 1) 0%,
    rgba(13, 181, 200, 1) 100%
  );
}
.btn-primary:hover {
  background: linear-gradient(
    to right,
    rgba(13, 181, 200, 1) 0%,
    rgba(8, 155, 171, 1) 100%
  ) !important;
  border-color: #0db5c8;
}
.ant-btn-text:not(:disabled):not(.ant-btn-disabled) {
  color: rgba(0, 0, 0, 0.88) !important;
  background: rgba(0, 0, 0, 0.06) !important;
}
.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-action {
  opacity: 1 !important;
}

/* loader spin */
.ant-spin .ant-spin-dot-item {
  color: rgba(8, 155, 171, 1);
  background-color: rgba(8, 155, 171, 1);
}
.cancel-danger {
  background: #fcecec !important;
  color: #e64141 !important;
}
.App-container {
  width: 80%;
  margin-top: 6%;
  margin-left: 20%;
}
.main-container {
  /* margin-left: 260px; */
  overflow: hidden;
  padding: 10px;
  min-height: 100vh;
  transition: all 0.3s ease-out 0s;
  background: #eff7f8;
  /* border-radius: 25px 0 0 25px; */
}
.sign-up-page-bg {
  padding: 10px 123px;
}
.side__navigation {
  width: 20%;
  background: rgba(8, 155, 171, 1);
  transition: all 0.5s;
}
.whole__section {
  display: flex;
}
.content__area {
  width: 80%;
}
.working__area {
  height: calc(100vh - 65px);
  overflow-y: scroll;
  overflow-x: hidden;
}
.background-sec {
  text-align: center;
  background: white;
  border-radius: 15px;
  margin: 2%;
  width: 95%;
  padding: 2%;
}
.ant-btn-primary {
  width: 40%;
  color: #ffffff;
  border-color: #089bab !important;
  background: linear-gradient(
    to right,
    rgba(8, 155, 171, 1) 0%,
    rgba(13, 181, 200, 1) 100%
  );
}
.ant-btn-primary:hover {
  background: linear-gradient(
    to right,
    rgba(13, 181, 200, 1) 0%,
    rgba(8, 155, 171, 1) 100%
  )!important;
  border-color: #0db5c8!important;
  color: white!important;
}

/* inputFile section */
button a.navLink {
  color: white !important;
}
/* Scroll bar design */

::-webkit-scrollbar {
  width: 5px;
  /* margin-left: 2px; */
  height: 2px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  margin-right: 5px;
}

::-webkit-scrollbar-thumb {
  background: #918b8b;
}

#sidebar.active {
  width: 6%;
  transition: all 0.5s;
}
#sidebar.active span {
  display: none;
}
#content.content_active {
  width: 100%;
  transition: all 0.5s;
}
.white_bg_sec {
  text-align: center;
  background: #fff;
  border-radius: 15px;
  padding: 20px;
  margin: 25px;
}
.ant-upload-wrapper .ant-upload-drag .upload_btn {
  background: linear-gradient(135deg, #aec1d6, #7bbbff) !important ;
  color: #225994 !important;
}
.working__area .white_bg_sec .background-sec {
  background: rgba(8, 155, 171, 1);
}
.ant-upload-wrapper .ant-upload-drag {
  background: #f3f2f2 !important;
}
.ant-upload-wrapper .ant-upload-drag .anticon svg {
  color: rgba(8, 155, 171, 1);
}
.working__area .white_bg_sec .ant-upload-wrapper,
.ant-upload-list-item-container,
.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-list-item-actions
  .anticon,
.ant-upload-wrapper
  .ant-upload-list
  .ant-upload-list-item
  .ant-upload-icon
  .anticon {
  color: #fff;
}
.upload_btn.ant-btn-primary {
  background: #fff;
  color: #225994 !important;
  height: 40px;
  font-size: 16px;
  font-weight: 500;
}
.upload_btn.ant-btn-primary:hover {
  background: #fff;
}
.white_bg_sec h3 {
  font-size: 30px;
  font-weight: 500;
}
.white_bg_sec p {
  font-size: 18px;
  font-weight: 400;
}
@media screen and (max-width: 480px) {
  .iq-menu span {
    display: none;
  }
  .heading-das {
    display: none;
  }
  .iq-sidebar-logo span {
    font-size: 17px;
  }
}
@media screen and (max-width: 992px) {
  .iq-sidebar-logo span {
    font-size: 24px;
  }
}
