/* sidebar css */
.iq-sidebar {
	z-index: 999;

	height: 100%;
	background: rgba(8, 155, 171, 1);
	color: white;
}
.iq-sidebar .iq-sidebar-logo {
	float: none;
	width: 100%;
}
.scrollbar {
	margin-top: 15px;
}

#sidebar-scrollbar {
	height: 100vh;
}
.iq-sidebar-menu {
	position: relative;
}
.iq-sidebar-menu .iq-menu {
	margin: 0 15px;
	padding: 0;
	list-style-type: none;
}
.iq-sidebar-menu .iq-menu .iq-menu-title {
	display: block;
	color: #ffffff;
	font-size: 15px;
	position: relative;
	padding: 15px;
	font-weight: 500;
	line-height: 18px;
}
.iq-sidebar-menu .iq-menu > li {
	/* border: 1px solid white; */
	margin-bottom: 0;
	background: transparent;
	border-radius: 25px;
	box-shadow: none;
}
.iq-sidebar-menu .iq-menu > li a {
	color: white;
	text-decoration: none;
}
.iq-sidebar-logo span {
	font-weight: 600;
	/* margin-left: 10px; */

	font-size: 28px;
	line-height: 45px;
	color: #ffffff;
	font-family: "Poppins", sans-serif;
	flex: 1;
	text-decoration: none;
}
.iq-sidebar-menu .iq-menu li .active {
	color: #089bab;

	text-decoration: none;
}
.iq-sidebar-menu .iq-menu li a {
	font-size: 16px;
	font-weight: 600;
	white-space: nowrap;
	display: block;
	color: #ffffff;
	position: relative;
	padding: 15px;
	line-height: 17px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	overflow: hidden;
	border-radius: 15px;
	text-transform: capitalize;
}
.iq-sidebar-menu .iq-menu > li .menu-open,
.iq-sidebar-menu .iq-menu > li .active {
	margin-bottom: 10px;
	background: #fff;
	box-shadow: 3px 2px 20px 1px rgba(0, 0, 0, 0.1);
}
.sign-info,
.login-info {
	border-top: 1px solid #cdd1f3;
}
.iq-menu li .svg-inline--fa {
	margin-right: 15px;
}
